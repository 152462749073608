@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css"; 
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css"; 
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


@font-face {
    font-family: 'MoonHouse';
    src: url('./assets/fonts/moonhouse.eot');
    src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";  
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

@-webkit-keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 

    transform : translate3d(-250px,0,0);
  }
}
@-moz-keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-250px,0,0);
  }
}
@keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}
@-moz-keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}
@keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}
@-moz-keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}
@keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}
select{
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.loader-outer {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0; 
    }
    .loader-container {
        height:100%;
        width:100%;
        display:table;
        .loader-inner {
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .loader{
                width:80px;
                margin:auto;
                position:relative;
                height:60px;
                .mask{
                    position:absolute;  
                    overflow:hidden;
                    -webkit-border-radius: 5px; 
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    .plane{
                        background:#DD1B16;
                        width:400%;
                        height:100%;
                        position:absolute;
                    } 
                }
                #top{
                    width:53px;
                    height:15px;
                    left:20px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0); 
                    .plane{
                        -webkit-animation : trans1 1.3s ease-in infinite  0s backwards;
                        -moz-animation : trans1 1.3s ease-in infinite  0s backwards;
                        animation : trans1 1.3s ease-in infinite  0s backwards;
                    }
                }
                #middle{
                    width:33px;
                    height:15px;
                    left:20px;
                    top:15px; 
                    -webkit-transform: skew(-15deg, 40deg);
                    -moz-transform: skew(-15deg, 40deg); 
                    transform: skew(-15deg, 40deg);
                    .plane{
                        transform : translate3d(0px,0,0);  
                        background : #DD1B16;
                        -webkit-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        -moz-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        animation : trans2 1.3s linear infinite  0.3s  backwards;
                    }
                }
                #bottom{
                    width:53px;
                    height:15px;
                    top:30px;  
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);
                    .plane{
                        -webkit-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        -moz-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        animation : trans3 1.3s ease-out infinite  0.7s backwards;
                    }
                }
                p.loader-text{
                    color:#000;
                    position:absolute;
                    top:42px;
                    font-family: 'MoonHouse';
                    font-size:16px;
                    span{
                        color: #DD1B16;
                    }
                }
            }      
        }           
    }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #cccccc;
    }
    &:active{
        background: #888888; 
    }
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #333333; 
    }
}
::-webkit-scrollbar-corner {
    background: transparent;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}


.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// mat-form-field {
//   height: 42px !important;
// }

// mat-option {
//   font-size: 12px !important;
//   min-height: 36px !important;
//   // border-bottom:1px solid #ced4da !important;
// }

// .mat-form-field-infix {
//   display: block;
//   /* position: relative; */
//   flex: auto;
//   min-width: 0;
//   width: 180px;
// }
// .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 1em 0 1em 0;
// }


// ::ng-deep {
//   .mat-form-field-appearance-outline .mat-form-field-flex {
//       padding: 0 0.75em 0 0.75em;
//       margin-top: -0.65em;
//       position: relative;
//   }
//   .mat-form-field-appearance-outline .mat-form-field-infix {
//       padding: 3px 0 1em 0;
//   }
//   .mat-form-field-infix {
//       display: block;
//       position: initial !important; 
//       flex: auto;
//       min-width: 0;
//       width: 180px;
//   }
// }
// ::ng-deep {
//   .mat-form-field-flex {
//       display: inline-flex;
//       align-items: center;
//       box-sizing: border-box;
//       width: 38vh !important;
//       background: white !important;
//       height: 37px !important;
//   }
// }

// ::ng-deep {
//   .mat-form-field-prefix,
//   .mat-form-field-suffix {
//       white-space: nowrap;
//       flex: none;
//       position: none !important;
//   }
//   .mat-form-field-appearance-outline .mat-form-field-prefix,
//   .mat-form-field-appearance-outline .mat-form-field-suffix {
//       margin: auto;
//   }
//   input.mat-input-element {
//       margin-top: -0.0625em;
//       width: 14vh;
//       height: 2vh;
//       margin-top: -15px;
//       display: block;
//   }
//   .mat-form-field-appearance-outline .mat-form-field-outline {
//       display: flex;
//       position: absolute;
//       top: 0em !important;
//       left: -8px !important;
//       right: 0;
//       bottom: 0;
//       pointer-events: none;
//       background-color: #fff;
//   }
// }

// .mat-select-panel {
//   position: absolute;
//   z-index: 99 !important;
// }

// ::ng-deep .cdk-overlay-container {
//   z-index: 9999;
// }


// h1,h2,h3,div,h4,h5,h6,p,b,small,span,input,select,label,button,td,table{
//   font-family:'Poppins' !important; 
// }
